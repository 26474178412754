import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox';
import { MapMarkers } from '../../features/map/MapMarkers'
import { AutoComplete } from 'primereact/autocomplete';
import { Avatar } from '../../features/widget/Avatar';
import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';
import { API_ROOT } from '../../constants/api';
import { MapWithASearchBoxAndMarkers } from '../../features/map/MapWithASearchBoxAndMarkers';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ScrollPanel } from 'primereact/scrollpanel';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { ExportExcel } from '../ExportExcel/ExportExcel';
import fileDownload from 'js-file-download';


class ReportOrder extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {

            adminName: '',
            memberType: 0,
            memberTypeItem: [
                { label: 'เลือกทั้งหมด', value: 0 },
                { label: 'ผู้รักษ์โลก', value: 1 },
                { label: 'ทีมขนส่งซีซาเล้ง', value: 2 },
                { label: 'ศูนย์จัดการฯซีซาเล้ง', value: 3 },
            ],
            date1: null,
            date2: null,
            date3: null,
            date4: null,
            date5: null,
            date6: null,
            selectedProductImg: null,
            status: 1000,
            status2: 0,
            statusSearchItem: [
                { label: 'เลือกทั้งหมด', value: 1000 },
                { label: 'รอร้านค้ายืนยัน', value: 0 },
                { label: 'รอนัดหมาย', value: 1 },
                { label: 'รอทีมขนส่ง', value: 2 },
                { label: 'รอส่งสินค้า', value: 3 },
                { label: 'รอปิด Order', value: 4 },
                { label: 'เกินกำหนดรับสินค้า', value: 5 },
                { label: 'ปิด Order', value: 88 },
                { label: 'ยกเลิก', value: 99 },
                { label: 'ผิดนัด', value: 999 },
                { label: 'ร้านค้าปฏิเสธรับซื้อ', value: 998 },
            ],
            statusItem: [
                { label: 'เลือกทั้งหมด', value: 1000 },
                { label: 'รอร้านค้ายืนยัน', value: 0 },
                { label: 'รอนัดหมาย', value: 1 },
                { label: 'รอทีมขนส่ง', value: 2 },
                { label: 'รอส่งสินค้า', value: 3 },
                { label: 'รอปิด Order', value: 4 },
                { label: 'เกินกำหนดรับสินค้า', value: 5 },
                { label: 'ปิด Order', value: 88 },
                { label: 'ยกเลิก', value: 99 },
                { label: 'ผิดนัด', value: 999 },
                { label: 'ร้านค้าปฏิเสธรับซื้อ', value: 998 },
            ],
            manageTable: [
                1, 2, 3, 4, 5, 6, 7
            ],

            OrderList: [
                // wasteType: '',
                // amount: '',
                // appraisedPrice: '',
                // salePrice: '',
            ],
            brandSuggestions: null,
            addressDetail: '',
            lineId: '',
            email: '',
            genPhone: '',
            deliveryCost: 0,
            ModelInputLocation: {
                latitude: "13.7734283",
                longitude: "100.57383099999993",
                distance_km: 10
            },
            wastetranDataList: [],
            wasteproDataList: [],
            amountSelected: '',
            appraisedPriceSelected: '',
            salePriceSelected: '',
            dataTableSelection2: '',
            dataTableSelection3: '',
            genImg: null,
            tranImg: null,
            proImg: null,
            personPrefixCode: '',
            personPrefix: '',
            orderID: null,
            orderDetail: [],
            gid: 0,
            showProgress: false,
            reportSearchOrders: [],
            orderCode: null,
            showReportPanel: true,
            memberUid: null,
            showReportTable: true,
            excelDisable: true
        };
        this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
        this.onproductsChange = this.onproductsChange.bind(this);
        this.onUsernameChanged = this.onUsernameChanged.bind(this);
        this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
        this.onOrderIDChanged = this.onOrderIDChanged.bind(this);
        //this.onSelectAutoCompleteSearchName = this.onSelectAutoCompleteSearchName.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectOrderID = this.onSelectOrderID.bind(this);
        //this.getProfile = this.getProfile.bind(this);
        this.onDate1Change = this.onDate1Change.bind(this);
        this.onDate2Change = this.onDate2Change.bind(this);
        this.onDate3Change = this.onDate3Change.bind(this);
        this.onDate4Change = this.onDate4Change.bind(this);
        this.onDate5Change = this.onDate5Change.bind(this);
        this.onDate6Change = this.onDate6Change.bind(this);
        this.searchOrder = this.searchOrder.bind(this);
        this.onOrderSelected = this.onOrderSelected.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.showProgressSpinner = this.showProgressSpinner.bind(this);
        this.quantityEditor = this.quantityEditor.bind(this);
        this.estimatePricePerUnitEditor = this.estimatePricePerUnitEditor.bind(this)
        this.deliveryQuantityEditor = this.deliveryQuantityEditor.bind(this)
        this.deliveryPricePerUnitEditor = this.deliveryPricePerUnitEditor.bind(this)
        this.remarkEditor = this.remarkEditor.bind(this)
        this.showReportResult = this.showReportResult.bind(this);
        this.distanceTemplate = this.distanceTemplate.bind(this);
        this.onSelectAdminName = this.onSelectAdminName.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
        this.to_created_date = this.to_created_date.bind(this);
        this.reConstruct = this.reConstruct.bind(this);
        this.searchOrderDownload = this.searchOrderDownload.bind(this);
    }


    componentDidMount() {
        const { dashBoardServiceProps, locationServiceProps, masterServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        locationServiceProps.getProvice();
        masterServiceProps.getProductType();
    }



    onDate1Change(e) { //date in order edit
        //console.log(e.value.toISOString())
        if (this.state.date2 != null) {
            if (e.value <= this.state.date2) {
                this.setState({ date1: e.value })
            }
        } else
            this.setState({ date1: e.value })
    }
    onDate2Change(e) { //date in order edit 
        if (this.state.date1 <= e.value) {
            this.setState({ date2: e.value })
        }
    }

    onDate3Change(e) { //date in search order
        //console.log(e.value.toISOString())
        if (this.state.date4 != null) {
            if (e.value <= this.state.date4) {
                this.setState({ date3: e.value })
            }
        } else
            this.setState({ date3: e.value })
    }
    onDate4Change(e) { //date in search order
        if (this.state.date3 <= e.value) {
            this.setState({ date4: e.value })
        }
    }

    onDate5Change(e) { //date in search order
        //console.log(e.value.toISOString())
        if (this.state.date6 != null) {
            if (e.value <= this.state.date6) {
                this.setState({ date5: e.value })
            }
        } else
            this.setState({ date5: e.value })
    }
    onDate6Change(e) { //date in search order
        if (this.state.date5 <= e.value) {
            this.setState({ date6: e.value })
        }
    }
    onSelectAdminName(item) {
        this.setState({ adminName: item.value.search_name });
        this.setState({ adminUid: item.value.uid });
    }


    suggestAdminName(event) { }
    onAdminNameChanged(search_name) {
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ adminName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchadminAll(search_name).then((res) => {
                        this.setState({ adminSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ adminName: search_name });
                this.setState({ adminUid: null });
            }
    }

    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    addDataToTable() {
        var isNotinput = (this.state.wasteTypeSelected == '' ||
            this.state.productSelected == '' ||
            this.state.amountSelected == '' ||
            this.state.appraisedPriceSelected == '' ||
            this.state.salePriceSelected == ''
        );

        if (isNotinput) {
            this.showWarn();
        } else {
            var OrderDetailList = {
                gid: this.state.gid,
                product_type_code: this.state.wasteTypeSelected.product_type_code,
                product_code: this.state.productSelected.product_code,
                product_name: this.state.productSelected.product_name,
                // wasteType: this.state.wasteTypeSelected.product_type_name,
                // amount: parseFloat(this.state.amountSelected).toFixed(2),
                // appraisedPrice: parseFloat(this.state.appraisedPriceSelected).toFixed(2),
                // salePrice: parseFloat(this.state.salePriceSelected).toFixed(2),    

                delivery_quantity_kk: 0,
                delivery_price_per_unit: 0,
                quantity_kk: parseFloat(this.state.amountSelected).toFixed(2),
                estimate_price_per_unit: parseFloat(this.state.appraisedPriceSelected).toFixed(2),
                price_per_unit: parseFloat(this.state.salePriceSelected).toFixed(2),
            }
            this.state.orderDetail.push(OrderDetailList)

            this.setState({ wasteTypeSelected: '' })
            this.setState({ productSelected: '' })
            this.setState({ amountSelected: '' })
            this.setState({ appraisedPriceSelected: '' })
            this.setState({ salePriceSelected: '' })
            console.log(this.state.orderDetail)
            this.showSuccess();
        }
    }
    onWasteTypeChange(e) {

        this.setState({ wasteTypeSelected: e.value });
        this.setState({ productTypeCode: e.value.product_type_code });

        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);

    }
    onproductsChange(e) {
        this.setState({ productSelected: e.value });
    }

    // onFileWasteChanged(e) {
    //     console.log(e)
    //     //this.setState({ fileImgIden: e.target.files[0] });
    //     this.setState({ fileImgWaste: e.files[0] });
    // }

    //auto complate handle (ผู้รักษ์โลก)
    suggestBrands(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */
        //this.setState({ brandSuggestions: event });
    }
    onUsernameChanged(search_name) {
        this.setState({ brand: search_name });
        if (search_name.length >= 2) {
            ServerAPI.searchMemberName(search_name).then((res) => {
                console.log(res);
                this.setState({ brandSuggestions: res });

            }).catch((error) => {
            })
        }
    }
    // onSelectAutoCompleteSearchName(item) {
    //     this.setState({ brand: item.value.search_name });
    //     this.setState({ u1_uid: item.value.uid })
    //     this.getProfile(item.value.uid)
    // }



    //auto complate handle (ค้นหาสมาชิก)
    suggestMemberName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwasteAll(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }

    //auto complate handle (ค้นหา Order ID)
    suggestOrderID(event) {
        //console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */
        //this.setState({ brandSuggestions: event });
    }

    onOrderIDChanged(code) {
        //console.log(code)
        if (code.trim() != "") {
            this.setState({ orderID: code });
            if (code.length > 1) {
                ServerAPI.autoComplateOrderID(code).then((res) => {
                    console.log(res);
                    this.setState({ orderIDSuggestions: res });
                }).catch((error) => {
                })
            }
        } else this.setState({ orderID: code });


    }
    onSelectOrderID(item) {
        this.setState({ orderID: item.value });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    itemOrderIDTemplate(item) {
        return (
            <div className="p-clearfix">
                {item}
            </div>
        );
    }

    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    clearSearchInput() {
        this.setState({ orderCode: null }); //order code from select table
        this.setState({ orderID: null }); //order code from auto complete
        this.setState({ memberUid: null });
        this.setState({ memberName: '' });
        this.setState({ adminUid: null });
        this.setState({ adminName: '' });
        this.setState({ memberType: 0 });
        this.setState({ date1: null });
        this.setState({ date2: null });
        this.setState({ date3: null });
        this.setState({ date4: null });
        this.setState({ date5: null });
        this.setState({ date6: null });
        this.setState({ status: 1000 });
        var reportSearchList = {}
        this.state.reportSearchOrders.push(reportSearchList)
        this.setState({ reportSearchOrders: [] });
        this.setState({ excelDisable: true });

    }







    searchOrder() {
        this.setState({ showReportTable: true })
        this.setState({ showProgress: true })
        var reportSearchList = []
        this.state.reportSearchOrders.push(reportSearchList)
        this.setState({ reportSearchOrders: [] });
        // const formData = new FormData();
        // formData.set('order_code', this.state.orderID || '');
        // formData.set('user_search_name', this.state.memberName || '');
        // formData.set('user_wasteTypes', this.state.memberType || 0);
        // formData.set('pickup_date_start', this.state.date3.toISOString() || '');
        // formData.set('pickup_date_end', this.state.date4.toISOString() || '');
        // //formData.set('status_code', this.state.status || 0);
        // //formData.set('admin_code', this.state.genFname || '');

        var searchModel = {
            order_code: this.state.orderID,
            user_search_name: this.state.memberUid,
            user_wasteTypes: this.state.memberType,
            order_status: this.state.status,
            admin_code: "",
            admin_code: this.state.adminUid,
        };
        if (this.state.date1 != null) {
            //Bankok Time zone (+7hrs)
            var date1 = new Date(this.state.date1.getTime() + 7 * 60 * 60000);
            searchModel.pickup_date_start = date1.toISOString();
        }
        if (this.state.date2 != null) {
            var date2 = new Date(this.state.date2.getTime() + 7 * 60 * 60000);
            searchModel.pickup_date_end = date2.toISOString();
        }

        if (this.state.date3 != null) {
            //Bankok Time zone (+7hrs)
            var date3 = new Date(this.state.date3.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_start = date3.toISOString();
        }
        if (this.state.date4 != null) {
            var date4 = new Date(this.state.date4.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_end = date4.toISOString();
        }
        if (this.state.date5 != null) {
            //Bankok Time zone (+7hrs)
            var date5 = new Date(this.state.date5.getTime() + 7 * 60 * 60000);
            searchModel.close_date_start = date5.toISOString();
        }
        if (this.state.date6 != null) {
            var date6 = new Date(this.state.date6.getTime() + 7 * 60 * 60000);
            searchModel.close_date_end = date6.toISOString();
        }

        ServerAPI.searchReportOrder(searchModel).then((res) => {
            console.log("response");
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    console.log(res);
                    if (res.length > 0) {
                        var array = res;
                        var listOfOrder = [];
                        for (let i in array) {
                            var list = array[i].orderDetail;
                            for (let j in list) {
                                var obj = list[j];
                                obj.estimate_price = Number((obj.quantity_kk * obj.estimate_price_per_unit).toFixed(2));
                                obj.sale_price = Number((obj.delivery_quantity_kk * obj.price_per_unit).toFixed(2));

                                var Objmaster = Object.assign({}, array[i]);
                                Objmaster = Object.assign(obj, Objmaster);
                                if (j != 0) {
                                    Objmaster.delivery_cost = 0;
                                    Objmaster.process_fee = 0;
                                }
                                listOfOrder.push(Objmaster);
                            }
                        }
                        this.setState({ reportSearchOrders: listOfOrder });
                        this.reConstruct(listOfOrder)
                        this.setState({ showProgress: false })
                    } else {
                        this.showWarn("ไม่พบข้อมูล");
                        this.setState({ showProgress: false })
                    }
                    // this.showSuccess();
                    // this.clearAllDataInput();
                } else {
                    this.showWarn();
                }

            } else {
                this.showWarn();
            }
        }).catch((error) => {
        })
    }

    searchOrderDownload() {
        this.setState({ showProgress: true })

        var searchModel = {
            order_code: this.state.orderID,
            user_search_name: this.state.memberUid,
            user_wasteTypes: this.state.memberType,
            order_status: this.state.status,
            admin_code: "",
            admin_code: this.state.adminUid,
        };
        if (this.state.date1 != null) {
            //Bankok Time zone (+7hrs)
            var date1 = new Date(this.state.date1.getTime() + 7 * 60 * 60000);
            searchModel.pickup_date_start = date1.toISOString();
        }
        if (this.state.date2 != null) {
            var date2 = new Date(this.state.date2.getTime() + 7 * 60 * 60000);
            searchModel.pickup_date_end = date2.toISOString();
        }

        if (this.state.date3 != null) {
            //Bankok Time zone (+7hrs)
            var date3 = new Date(this.state.date3.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_start = date3.toISOString();
        }
        if (this.state.date4 != null) {
            var date4 = new Date(this.state.date4.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_end = date4.toISOString();
        }
        if (this.state.date5 != null) {
            //Bankok Time zone (+7hrs)
            var date5 = new Date(this.state.date5.getTime() + 7 * 60 * 60000);
            searchModel.close_date_start = date5.toISOString();
        }
        if (this.state.date6 != null) {
            var date6 = new Date(this.state.date6.getTime() + 7 * 60 * 60000);
            searchModel.close_date_end = date6.toISOString();
        }

        searchModel.is_excel = true;

        ServerAPI.searchReportOrderDownload(searchModel).then((res) => {
            console.log("response")
            console.log(res)
            fileDownload(res, 'รายงานตรวจสอบ_orders.xlsx')
            this.setState({ showProgress: false })
        }).catch((error) => {
        })
    }

    onOrderSelected(e) {
        console.log(e.value);
        this.setState({ orderSelection: e.value })

        this.setState({ orderCode: e.value.order_code })

    }

    //get 1 order from table for update






    //edit table handle
    onEditorQuantityChange(props, value) {
        if (value == "") {// null set to 0
            value = '0';
        } else {
            var regex = /^[0-9]*(\.[0-9]{0,2})?$/;
            if (!regex.test(value)) { // set 2 decimal if more decimal
                if (value % 1 !== 0) {
                    value = parseFloat(value).toFixed(2)
                }
            }
            if (value.indexOf('.') < 1) {//no 0 leading
                value = parseFloat(value)
            }
        }
        let updatedCars = [...props.value];
        updatedCars[props.rowIndex][props.field] = value;
        this.setState({ orderDetail: updatedCars });
    }
    onEditorTextChange(props, value) {
        let updatedCars = [...props.value];
        updatedCars[props.rowIndex][props.field] = value;
        this.setState({ orderDetail: updatedCars });
    }
    inputQuantityEditor(props, field) {
        return <InputText type="text" keyfilter="money" value={props.rowData[field]} onChange={(e) => this.onEditorQuantityChange(props, e.target.value)} />;
    }
    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorTextChange(props, e.target.value)} />;
    }
    quantityEditor(props) {
        return this.inputQuantityEditor(props, 'quantity_kk');
    }
    estimatePricePerUnitEditor(props) {
        return this.inputQuantityEditor(props, 'estimate_price_per_unit');
    }
    deliveryQuantityEditor(props) {
        return this.inputQuantityEditor(props, 'delivery_quantity_kk');
    }
    deliveryPricePerUnitEditor(props) {
        return this.inputQuantityEditor(props, 'price_per_unit');
    }

    remarkEditor(props) {
        return this.inputTextEditor(props, 'remark');
    }

    actionTemplate(rowData, column) {
        //console.log(rowData);
        let price = (rowData.quantity_kk * rowData.estimate_price_per_unit).toFixed(2);
        return <div>
            <span> {price}</span>
        </div>;
    }
    actionDeliveryPriceTemplate(rowData, column) {
        //console.log(rowData);
        let price = (rowData.delivery_quantity_kk * rowData.price_per_unit).toFixed(2);
        return <div>
            <span> {price}</span>
        </div>;
    }
    distanceTemplate(rowData, column) {
        let distance = rowData.distance_km.toFixed(3);
        return <div>
            <span> {distance}</span>
        </div>;
    }

    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }
    showReportResult() {
        if (this.state.showReportTable) {
            this.setState({ showReportTable: false })
        } else {
            this.setState({ showReportTable: true })
        }
    }
    actionTemplate(rowData, column) {
        //console.log(rowData);
        let price = (rowData.quantity_kk * rowData.estimate_price_per_unit).toFixed(2);
        return <div>
            <span> {price}</span>
        </div>;
    }
    actionTemplate1(rowData, column) {
        //console.log(rowData);
        let price = (rowData.delivery_quantity_kk * rowData.price_per_unit).toFixed(2);
        return <div>
            <span> {price}</span>
        </div>;
    }
    to_double(rowData, column) {
        if (rowData.process_fee.length > 3)
            console.log(rowData.process_fee)
        let fee = rowData.process_fee.toFixed(2)
        return <div>
            <span> {fee}</span>
        </div>;
    }
    toFormatDateTime(dateTime) {
        let _dateTime = new Date(dateTime);
        let year = _dateTime.getYear();

        if (year == -1899)
            return <div><span></span></div>;
        else
            return <div><Moment format="DD/MM/YYYY">
                {dateTime}
            </Moment><br /><Moment format="HH:mm">
                    {dateTime}
                </Moment></div>;
    }
    to_transfer_date(rowData, column) {
        return this.toFormatDateTime(rowData.delivery_date);
    }
    to_requested_start_date(rowData, column) {
        return this.toFormatDateTime(rowData.pickup_start_date);
    }
    to_requested_end_date(rowData, column) {
        return this.toFormatDateTime(rowData.pickup_end_date);
    }
    to_close_date(rowData, column) {
        return this.toFormatDateTime(rowData.close_date);
    }
    to_created_date(rowData, column) {
        return this.toFormatDateTime(rowData.created_date);
    }
    ExportButton(rowData, column) {
        return <div style={{ textAlign: 'center' }}>
            <Button type="button" icon="fa fa-print" className="p-button-success" ></Button>
        </div>;
    }
    reConstruct(listOfOrder) {
        console.log('Do')
        var tmp = []
        const Objmaster = listOfOrder || [];
        const total = {
            quantity_kk: 0,
            pickup_quantity_kk: 0,
            delivery_quantity_kk: 0,
            estimate_price_per_unit: 0,
            estimate_price: 0,
            price_per_unit: 0,
            sale_price: 0,
            delivery_cost: 0,
            process_fee: 0,
        };
        if (Objmaster.length > 0)
            for (let i = 0; i < Objmaster.length; i++) {
                const obj = {
                    "์NO.": Objmaster[i].number_of_sequence,
                    "ORDER ID": Objmaster[i].order_code,
                    "รายการสินค้า": Objmaster[i].product_name,
                    "ชื่อ U1": Objmaster[i].u1_fullname,
                    "ชื่อ U2": Objmaster[i].u2_fullname,
                    "ชื่อ U3": Objmaster[i].u3_fullname,
                    "วันเปิด order": (new Date(Objmaster[i].created_date)).toLocaleDateString(),
                    "วันนัดรับเริ่ม": (new Date(Objmaster[i].pickup_start_date)).toLocaleDateString(),
                    "วันนัดรับสิ้นสุด": (new Date(Objmaster[i].pickup_end_date)).toLocaleDateString(),
                    "วันส่ง": (new Date(Objmaster[i].delivery_date)).toLocaleDateString(),
                    "วันปิด order": (new Date(Objmaster[i].close_date).getYear() == -1899) ? '' : (new Date(Objmaster[i].close_date)).toLocaleDateString(),
                    "ปริมาณประเมิน (Kg)": Objmaster[i].quantity_kk,
                    "ปริมาณเก็บ (Kg)": Objmaster[i].pickup_quantity_kk,
                    "ปริมาณส่ง (Kg)": Objmaster[i].delivery_quantity_kk,
                    "ราคาประเมิน/หน่วย": Objmaster[i].estimate_price_per_unit,
                    "ราคาประเมิน (บาท)": Number((Objmaster[i].quantity_kk * Objmaster[i].estimate_price_per_unit).toFixed(2)),
                    "ราคาขาย/หน่วย": Objmaster[i].price_per_unit,
                    "ราคาขาย (บาท)": Number((Objmaster[i].delivery_quantity_kk * Objmaster[i].price_per_unit).toFixed(2)),
                    "ค่าขนส่ง (บาท)": Objmaster[i].delivery_cost,
                    "ค่าบริการ (บาท)": Objmaster[i].process_fee,
                    "สถานะ": Objmaster[i].order_status_name,
                    "หมายเหตุ": Objmaster[i].remark,
                    "ผู้ดูแล": Objmaster[i].admin_name,
                }
                total.quantity_kk += Number(Objmaster[i].quantity_kk);
                total.pickup_quantity_kk += Number(Objmaster[i].pickup_quantity_kk);
                total.delivery_quantity_kk += Number(Objmaster[i].delivery_quantity_kk);
                total.estimate_price_per_unit += Number(Objmaster[i].estimate_price_per_unit);
                total.estimate_price += Number(Objmaster[i].quantity_kk * Objmaster[i].estimate_price_per_unit);
                total.price_per_unit += Number(Objmaster[i].price_per_unit);
                total.sale_price += Number(Objmaster[i].delivery_quantity_kk * Objmaster[i].price_per_unit);
                total.delivery_cost += Number(Objmaster[i].delivery_cost);
                total.process_fee += Number(Objmaster[i].process_fee);
                tmp.push(obj);
                //console.log(tmp);
            }
        //The last record
        const obj = {
            "ปริมาณประเมิน (Kg)": total.quantity_kk,
            "ปริมาณเก็บ (Kg)": total.pickup_quantity_kk,
            "ปริมาณส่ง (Kg)": total.delivery_quantity_kk,
            "ราคาประเมิน/หน่วย": total.estimate_price_per_unit,
            "ราคาประเมิน (บาท)": total.estimate_price,
            "ราคาขาย/หน่วย": total.price_per_unit,
            "ราคาขาย (บาท)": total.sale_price,
            "ค่าขนส่ง (บาท)": total.delivery_cost,
            "ค่าบริการ (บาท)": total.process_fee,
        }
        tmp.push(obj);
        //End the last record

        this.setState({ excelData: tmp })
        this.setState({ excelDisable: false })
    }
    showSearchResult() {
        let sum_quantity_kk = 0;
        let sum_pickup_quantity_kk = 0;
        let sum_delivery_quantity_kk = 0;
        let sum_estimate_price_per_unit = 0;
        let sum_estimate_price = 0;
        let sum_price_per_unit = 0;
        let sum_sale_price = 0;
        let sum_delivery_cost = 0;
        let sum_process_fee = 0;
        if (this.state.reportSearchOrders)
            if (this.state.reportSearchOrders.length > 0) {
                this.state.reportSearchOrders.map(function (a) {
                    let quantity_kk = a.quantity_kk;
                    sum_quantity_kk += quantity_kk;

                    let pickup_quantity_kk = a.pickup_quantity_kk;
                    sum_pickup_quantity_kk += pickup_quantity_kk;

                    let delivery_quantity_kk = a.delivery_quantity_kk;
                    sum_delivery_quantity_kk += delivery_quantity_kk;

                    let estimate_price_per_unit = a.estimate_price_per_unit;
                    sum_estimate_price_per_unit += estimate_price_per_unit;

                    let estimate_price = a.quantity_kk * a.estimate_price_per_unit
                    sum_estimate_price += estimate_price;

                    let price_per_unit = a.price_per_unit
                    sum_price_per_unit += price_per_unit;

                    let sale_price = a.delivery_quantity_kk * a.price_per_unit
                    sum_sale_price += sale_price;

                    let delivery_cost = a.delivery_cost
                    sum_delivery_cost += delivery_cost;

                    let process_fee = a.process_fee
                    sum_process_fee += process_fee;

                });
            }
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="รวม:" colSpan={11} style={{ width: '1514px', textAlign: 'right' }} />
                <Column footer={sum_quantity_kk.toFixed(2)} style={{ width: '101px', textAlign: 'right' }} />
                <Column footer={sum_pickup_quantity_kk.toFixed(2)} style={{ width: '101px', textAlign: 'right' }} />
                <Column footer={sum_delivery_quantity_kk.toFixed(2)} style={{ width: '101px', textAlign: 'right' }} />
                <Column footer={sum_estimate_price_per_unit.toFixed(2)} style={{ width: '101px', textAlign: 'right' }} />
                <Column footer={sum_estimate_price.toFixed(2)} style={{ width: '101px', textAlign: 'right' }} />
                <Column footer={sum_price_per_unit.toFixed(2)} style={{ width: '101px', textAlign: 'right' }} />
                <Column footer={sum_sale_price.toFixed(2)} style={{ width: '101px', textAlign: 'right' }} />
                <Column footer={sum_delivery_cost.toFixed(2)} style={{ width: '101px', textAlign: 'right' }} />
                <Column footer={sum_process_fee.toFixed(2)} style={{ width: '101px', textAlign: 'right' }} />
            </Row>
        </ColumnGroup>;
        if (this.state.showProgress) return <div></div>;



        var exportExcel =
            <div style={{ textAlign: 'left' }}>
                <ExportExcel data={this.state.excelData} isDisable={this.state.excelDisable} fileName="รายงานตรวจสอบ Order" />
            </div>;
        return (<div><div className="p-col-12">

            {/* {exportExcel} */}
            <Panel header="รายงานตรวจสอบ Orders">

                <DataTable 
                    // header={exportExcel} 
                    value={this.state.reportSearchOrders} paginatorPosition="bottom-left" paginator={true} rows={15}
                    responsive={true} selection={this.state.dataTableSelection}
                    scrollable={true} rowsPerPageOptions={[5, 10, 15]}
                    footerColumnGroup={footerGroup}>
                    <Column field="number_of_sequence" header="NO." sortable={true} filter={true} className="p-text-center" style={{ width: '5em' }} />
                    <Column field="order_code" header="ORDER ID" sortable={true} filter={true} style={{ width: '10em' }} />
                    <Column field="product_name" header="รายการสินค้า" sortable={true} filter={true} style={{ width: '200px' }} />
                    <Column field="u1_fullname" header="ชื่อ U1" sortable={true} filter={true} style={{ width: '200px' }} />
                    <Column field="u2_fullname" header="ชื่อ U2" sortable={true} filter={true} style={{ width: '200px' }} />
                    <Column field="u3_fullname" header="ชื่อ U3" sortable={true} filter={true} style={{ width: '200px' }} />
                    <Column field="created_date" body={this.to_created_date.bind(this)} header="วันเปิด order" filter={true} className="p-text-center" style={{ width: '10em' }} />
                    <Column field="pickup_start_date" body={this.to_requested_start_date.bind(this)} header="วันนัดรับเริ่ม" filter={true} className="p-text-center" style={{ width: '10em' }} />
                    <Column field="pickup_end_date" body={this.to_requested_end_date.bind(this)} header="วันนัดรับสิ้นสุด" filter={true} className="p-text-center" style={{ width: '10em' }} />
                    <Column field="delivery_date" body={this.to_transfer_date.bind(this)} header="วันส่ง" filter={true} className="p-text-center" style={{ width: '10em' }} />
                    <Column field="close_date" body={this.to_close_date.bind(this)} header="วันปิด order" filter={true} className="p-text-center" style={{ width: '10em' }} />
                    <Column field="quantity_kk" header="ปริมาณประเมิน (Kg)" sortable={true} filter={true} className="p-text-right" style={{ width: '100px' }} />
                    <Column field="pickup_quantity_kk" header="ปริมาณเก็บ (Kg)" sortable={true} filter={true} className="p-text-right" style={{ width: '100px' }} />
                    <Column field="delivery_quantity_kk" header="ปริมาณส่ง (Kg)" sortable={true} filter={true} className="p-text-right" style={{ width: '100px' }} />
                    <Column field="estimate_price_per_unit" header="ราคาประเมิน/หน่วย" sortable={true} filter={true} className="p-text-right" style={{ width: '100px' }} />
                    <Column field='estimate_price' body={this.actionTemplate} header="ราคาประเมิน (บาท)" filter={true} className="p-text-right" style={{ width: '100px' }} />
                    <Column field="price_per_unit" header="ราคาขาย/หน่วย" sortable={true} filter={true} className="p-text-right" style={{ width: '100px' }} />
                    <Column field='sale_price' body={this.actionTemplate1} header="ราคาขาย (บาท)" filter={true} className="p-text-right" style={{ width: '100px' }} />
                    <Column field="delivery_cost" header="ค่าขนส่ง (บาท)" sortable={true} filter={true} className="p-text-right" style={{ width: '100px' }} />
                    <Column field="process_fee" header="ค่าบริการ(บาท)" body={this.to_double.bind(this)} sortable={true} filter={true} className="p-text-right" style={{ width: '100px' }} />
                    <Column field="order_status_name" header="สถานะ" sortable={true} filter={true} className="p-text-center" style={{ width: '120px' }} />
                    <Column field="remark" header="หมายเหตุ" sortable={true} filter={true} style={{ width: '200px' }} />
                    <Column field="admin_name" header="ผู้ดูแล" sortable={true} filter={true} style={{ width: '200px' }} />
                </DataTable>
            </Panel>
        </div></div>);
    }


    showSearchPanel() {

        const searchResult = this.showSearchResult();
        return (
            <div>
                <Accordion multiple={true} activeIndex={[0, 1]}>
                    <AccordionTab header="เงื่อนไขค้นหา Orders" contentStyle={{ width: '1550px' }}>
                        <div className="p-grid p-justify-start" >
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>Order ID :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.orderID}
                                    suggestions={this.state.orderIDSuggestions}
                                    onSelect={this.onSelectOrderID}
                                    completeMethod={this.suggestOrderID.bind(this)}
                                    onChange={(e) => this.onOrderIDChanged(e.value)}
                                    onKeyPress={this.onOrderKeyPress}
                                    itemTemplate={this.itemOrderIDTemplate.bind(this)}
                                    placeholder="Order ID " />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>สมาชิก : </label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.memberName}
                                    suggestions={this.state.memberSuggestions}
                                    onSelect={this.onSelectMemberName}
                                    completeMethod={this.suggestMemberName.bind(this)}
                                    onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                    itemTemplate={this.itemTemplate.bind(this)}
                                    placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>สถานะ :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-7" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Dropdown
                                    value={this.state.status}
                                    options={this.state.statusSearchItem}
                                    onChange={(e) => { this.setState({ status: e.value }) }}
                                >
                                </Dropdown>
                            </div>


                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ช่วงวันนัดรับ :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date1} onChange={this.onDate1Change} ></Calendar>
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ถึงวันที่ :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-7" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date2} onChange={this.onDate2Change} ></Calendar>
                            </div>

                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ช่วงวันส่ง :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date3} onChange={this.onDate3Change} ></Calendar>
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ถึงวันที่ :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-7" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date4} onChange={this.onDate4Change} ></Calendar>
                            </div>

                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ช่วงวันปิด order :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date5} onChange={this.onDate5Change} ></Calendar>
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ถึงวันที่ :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-7" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date6} onChange={this.onDate6Change} ></Calendar>
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ผู้ดูแล : </label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.adminName}
                                    suggestions={this.state.adminSuggestions}
                                    onSelect={this.onSelectAdminName}
                                    completeMethod={this.suggestAdminName.bind(this)}
                                    onChange={(e) => this.onAdminNameChanged(e.target.value)}
                                    itemTemplate={this.itemTemplate.bind(this)}
                                    placeholder="ระบุรหัสผู้ดูแล/ชื่อผู้ดูแล" />
                            </div>



                            <div className="p-col-12">
                                <Button
                                    style={{ marginRight: "20px" }}
                                    label="ค้นหา"
                                    icon="fa fa-search"
                                    iconPos="left"
                                    onClick={this.searchOrder} />
                                <Button
                                    style={{ backgroundColor: '#f0ad4e' }}
                                    type="submit"
                                    label="ยกเลิก"
                                    onClick={this.clearSearchInput}
                                />
                                <Button
                                    style={{ marginLeft: "20px" }}
                                    label="ดาว์นโหลด Excel"
                                    icon="fa fa-download"
                                    iconPos="left"
                                    onClick={this.searchOrderDownload} />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                {searchResult}
            </div>
        )
    }

    showOrderDetail() {
        const {
            provinces,
        } = this.props;

    }

    render() {
        const searchPanel = this.showSearchPanel();
        const orderDetail = this.showOrderDetail();
        const showProgress = this.showProgressSpinner();
        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} />
                {searchPanel}
                {showProgress}
                {orderDetail}
            </div>
        );
    }
}



const mapStateToProps = (state) => ({

    dhbUsers: state.dhbAdmin.dhbUsers,
    locationMaster: state.location,
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWasteproProfile: state.updateWasteproProfile,
    banks: state.master.banks,
    productType: state.master.productType,
    product: state.master.product,
    getFileWastepro: state.getFileWastepro,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ReportOrder)